import { getIdentityInstance } from "./getIdentityInstance";

const deleteSession = async () => {
  const url = `${process.env.NEXT_PUBLIC_SETTINGS_API_BASE}/sessions`;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (e) {
    console.error("Error when deleting sessions: ", e);
    throw e;
  }
};

const logout = async (): Promise<void> => {
  try {
    const identityInstance = getIdentityInstance();
    if (identityInstance) {
      try {
        await deleteSession();
      } catch (error) {
        console.error("Error when deleting sessions during logout: ", error);
      }
      // Clear user session cookie
      document.cookie =
        "usermix=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

      //Logout and redirect user
      identityInstance.logout(window.location.href);
    }
  } catch (error) {
    console.error("Error when logout: ", error);
  }
};

export { logout };

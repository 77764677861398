import type { Article } from "../../types/content";
import type { ReadingContext } from "../../types/pulse";

import { getNewsMediaPulseTracker } from "..";
import { getAuthorsEmails } from "../helpers/getAuthorsEmails";
import { getWordCount } from "../helpers/getWordCount";
import { getSourceName } from "../helpers/getSourceCount";

const trackViewArticle = (
  article: Article,
  readingContext: ReadingContext,
  currentUrl: string,
) => {
  const newsMediaPulseTracker = getNewsMediaPulseTracker();

  newsMediaPulseTracker?.executeNewsMediaPulseCommand("trackViewArticle", {
    object: {
      articleId: article.article_id,
      accessLevel: article.is_premium ? "Paid" : "Free",
      accessLevelName: article.is_premium ? "premium" : "free",
      authors: getAuthorsEmails(article.authors),
      category: article.sponsor || article.category.title || "",
      contentId: article.article_id,
      name: article.title.value,
      tags: article.tags.map((tag) => tag.title),
      updated: article.changes.modified,
      wordCount: getWordCount(article.resources),
      page: undefined,
      "spt:custom": {
        article_format: article.article_layout,
        is_sponsored: !!article.sponsor,
        main_resource: article.main_resource?.type,
        published: article.changes.published,
        reading_context: readingContext,
        sponsor: article.sponsor,
        source: getSourceName(currentUrl),
        vignette_type: article.story_vignette.title,
      },
    },
  });
};

export { trackViewArticle };

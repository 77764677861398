import { getPulseTracker } from "..";

const trackOpenMenu = () => {
  const pulseTracker = getPulseTracker();

  pulseTracker?.executePulseCommand("track", "engagementEvent", {
    action: "Click",
    object: {
      id: "button-webMainMenu",
      type: "UIElement",
      elementType: "Button",
      name: "Web Main Menu Button",
    },
  });
};

export { trackOpenMenu };

import type { GetTeaserObjectArgs } from "../../types/pulse";

const getTeaserObject = ({
  article,
  imageUrl,
  index,
  context,
  curateContext,
  positionInBundle,
}: GetTeaserObjectArgs) => {
  return {
    id: `teaser-${article.article_id}`,
    type: "UIElement",
    elementType: "Teaser",
    name: article.teaser.title.value,
    layout: {
      elementSize: article.teaser.layout === "large" ? "large" : "small",
      imageUrl,
      name: article.teaser.layout === "large" ? "Large" : "Small",
    },
    placement: {
      curateContext,
      primaryPosition: index + 1,
      positionInBundle,
    },
    "spt:custom": {
      context,
      is_sponsored: article.meta?.is_sponsored,
      sponsor: article.sponsor,
      vignette: article.story_vignette?.title,
      story: article.story?.title,
      category: article.category.title,
    },
  };
};

export { getTeaserObject };

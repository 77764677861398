import { Identity } from "@schibsted/account-sdk-browser";

let identityInstance: Identity | undefined;

const getIdentityInstance = () => {
  if (!identityInstance) {
    identityInstance = new Identity({
      clientId: process.env.NEXT_PUBLIC_SPID_CLIENT_ID || "",
      redirectUri: process.env.NEXT_PUBLIC_SPID_REDIRECT_URL || "",
      env: process.env.NEXT_PUBLIC_SPID_ENV || "PRO",
      sessionDomain: process.env.NEXT_PUBLIC_SPID_COOKIE_DOMAIN || "",
    });
  }

  return identityInstance;
};

export { getIdentityInstance };

import { getPulseTracker } from "..";

const trackPremiumInformationBoxImpression = (url: string) => {
  const pulseTracker = getPulseTracker();

  pulseTracker?.executePulseCommand("track", "trackerEvent", {
    object: {
      id: "premiumInformationBox",
      type: "UIElement",
      elementType: "Subscription Promotion Area",
      name: "Premium Information Box",
    },
    target: {
      id: "webSalesPoster",
      type: "ExternalContent",
      name: "Web Salesposter",
      url,
    },
  });
};

export { trackPremiumInformationBoxImpression };

import { getPulseTracker } from "..";

const newsroom: string =
  process.env.NEXT_PUBLIC_UI_THEME === "ekonomi" ? "omninext" : "omni";

const trackInfoCardImpression = ({
  articleId,
  articleTitle,
  articleIsPremium,
}: {
  articleId: string;
  articleTitle: string;
  articleIsPremium?: boolean;
}) => {
  const pulseTracker = getPulseTracker();
  const url = pulseTracker?.pulseTracker.builders?.origin?.url;
  const accessLevel = articleIsPremium ? "Paid" : "Free";
  const accessLevelName = articleIsPremium ? "premium" : "free";

  pulseTracker?.executePulseCommand("track", "trackerEvent", {
    object: {
      id: `infoCard-${articleId}`,
      type: "UIElement",
      elementType: "InfoCard",
      name: `${articleTitle}`,
    },
    target: {
      id: `article:${articleId}`,
      type: "Article",
      name: `${articleTitle}`,
      url,
      accessLevel,
      accessLevelName,
      newsroom,
    },
  });
};

export { trackInfoCardImpression };

"use client";

import { useEffect } from "react";

import { logout } from "./logout";

const LogoutController = ({ shouldLogout }: { shouldLogout: boolean }) => {
  useEffect(() => {
    if (shouldLogout) {
      logout();
    }
  }, [shouldLogout]);

  return null;
};

export { LogoutController };

const isArticlePathname = /^\/[^/]*\/a\/[^/]*$/;

const getSourceName = (referer: string) => {
  const refererURL = new URL(referer);

  if (isArticlePathname.test(refererURL.pathname)) {
    return "Article";
  }

  const route = refererURL.pathname.match(/^\/[^/]*/);

  if (!route) {
    return undefined;
  }

  switch (route[0]) {
    case "/":
      return "Top";
    case "/senaste":
      return "Latest";
    default:
      return undefined;
  }
};

export { getSourceName };

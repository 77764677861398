import type { Resource } from "../../types/content";

const getWordCount = (resources: Resource[]) => {
  const text = resources.reduce((text, resource) => {
    if (resource.type === "Title" || resource.type === "Subheading") {
      return text.concat(" ", resource.text.value);
    }

    if (resource.type === "Text" || resource.type === "OriginByline") {
      return text.concat(
        " ",
        resource.paragraphs.reduce((pText, paragraph) => {
          if ("items" in paragraph) {
            return pText.concat(
              " ",
              paragraph.items.map((item) => item.value).join(" "),
            );
          } else if (paragraph.text) {
            return pText.concat(" ", paragraph.text.value);
          }

          return pText;
        }, ""),
      );
    }

    return text;
  }, "");

  return text.match(/([\wåäö]+)/gim)?.length || 0;
};

export { getWordCount };

const navigationTypeMap = {
  back_forward: "navigation",
  navigate: "navigation",
  prerender: "navigation",
  reload: "refresh",
};

const getNavigationType = () => {
  if (!window?.performance) {
    return "unknown";
  }

  const [navigationEntry] = window.performance.getEntriesByType(
    "navigation",
  ) as PerformanceNavigationTiming[];
  return navigationTypeMap[navigationEntry.type];
};

export { getNavigationType };

import PulseTracker from "@spt-tracking/pulse-sdk";
import { NewsMediaPulseTracker } from "@schibsted/pulse-news-media";

import type { OriginData } from "../types/pulse";
import type { EventBuilders } from "@spt-tracking/pulse-sdk";

import { getActorPromise } from "./helpers/getActorPromise";

function getDeployStage(stage?: string): "dev" | "pre" | "pro" {
  switch (stage) {
    case "dev":
      return "dev";
    case "pre":
      return "pre";
    case "pro":
      return "pro";
    default:
      return "dev";
  }
}

class PTracker {
  pulseTracker: PulseTracker;
  newsMediaPulseTracker: NewsMediaPulseTracker;
  oldUserId: string | undefined;
  oldIsPremium: boolean | undefined;
  queue: {
    cmd: keyof PulseTracker | keyof NewsMediaPulseTracker;
    args: any[];
    type: "newsMediaPulseTracker" | "pulseTracker";
  }[] = [];
  ready: boolean = false;

  constructor() {
    this.pulseTracker = new PulseTracker("omnise", {
      useBeacon: true,
      useBeaconWhenAvailable: true,
      deployStage: getDeployStage(process.env.NEXT_PUBLIC_PULSE_DEPLOY_STAGE),
    });

    const productTag = process.env.NEXT_PUBLIC_PULSE_PRODUCT_TAG;
    if (productTag) {
      this.pulseTracker.update(
        {
          provider: {
            productTag,
          },
        },
        true,
      );
    }

    this.newsMediaPulseTracker = new NewsMediaPulseTracker(this.pulseTracker);
  }

  init(consent: EventBuilders.Consents) {
    this.updateConsent(consent);
    this.dequeue();
    this.ready = true;
  }

  dequeue() {
    while (this.queue.length > 0) {
      const el = this.queue.shift();
      if (el) {
        const { cmd, args, type } = el;
        if (
          type === "pulseTracker" &&
          typeof this.pulseTracker[cmd as keyof PulseTracker] === "function"
        ) {
          // @ts-expect-error Typescript does not like passing arbitrary arguments to a function
          this.pulseTracker[cmd](...args);
        } else if (
          type === "newsMediaPulseTracker" &&
          typeof this.newsMediaPulseTracker[
            cmd as keyof NewsMediaPulseTracker
          ] === "function"
        ) {
          // @ts-expect-error Typescript does not like passing arbitrary arguments to a function
          this.newsMediaPulseTracker[cmd](...args);
        }
      }
    }
  }

  updatePulseUser(userId?: string, isPremium?: boolean) {
    if (userId !== this.oldUserId && isPremium !== this.oldIsPremium) {
      this.newsMediaPulseTracker = new NewsMediaPulseTracker(
        this.pulseTracker,
        getActorPromise(userId, isPremium),
      );

      this.oldUserId = userId;
      this.oldIsPremium = isPremium;
    }
  }

  updateConsent(consents: EventBuilders.Consents) {
    this.pulseTracker.setConsents(consents);
  }

  updateOrigin(origin: OriginData) {
    if (!this.ready) {
      this.queue.push({
        cmd: "update",
        args: [{ origin }],
        type: "pulseTracker",
      });
      return;
    }
    this.pulseTracker.update({ origin: origin }, true);
  }

  updateObjectPage(page: OriginData) {
    this.pulseTracker.update({
      object: {
        page: {
          id: page.id,
          type: page.type,
          name: page.name,
          url: page.url,
        },
      },
    });
  }

  getPulseTracker() {
    return this.pulseTracker;
  }

  getNewsMediaPulseTracker() {
    return this.newsMediaPulseTracker;
  }

  executePulseCommand(cmd: keyof PulseTracker, ...args: any[]) {
    if (this.ready) {
      // @ts-expect-error Typescript does not like passing arbitrary arguments to a function
      this.pulseTracker[cmd](...args);
    } else {
      this.queue.push({ cmd, args, type: "pulseTracker" });
    }
  }

  executeNewsMediaPulseCommand(
    cmd: keyof NewsMediaPulseTracker,
    ...args: any[]
  ) {
    if (this.ready) {
      // @ts-expect-error Typescript does not like passing arbitrary arguments to a function
      this.newsMediaPulseTracker[cmd](...args);
    } else {
      this.queue.push({ cmd, args, type: "newsMediaPulseTracker" });
    }
  }
}

// Returns Pulse class or creates it and returns it
const getPulseData = () => {
  if (typeof window !== "undefined") {
    const browser = window;
    if (!browser?.tracker) {
      const pTracker = new PTracker();
      browser.tracker = pTracker;

      return pTracker;
    } else {
      return browser.tracker;
    }
  }
};

const updatePulseUser = (userId?: string, isPremium?: boolean) => {
  const pTracker = getPulseData();
  pTracker?.updatePulseUser(userId, isPremium);
};

const updateConsent = (consents: EventBuilders.Consents) => {
  const pTracker = getPulseData();
  if (!pTracker?.ready) {
    pTracker?.init(consents);
    return;
  }
  pTracker?.updateConsent(consents);
};

const updateOrigin = (origin: OriginData) => {
  const pTracker = getPulseData();
  pTracker?.updateOrigin(origin);
};

const updateObjectPage = (page: OriginData) => {
  const pTracker = getPulseData();
  pTracker?.updateObjectPage(page);
};

const getPulseTracker = () => {
  return getPulseData();
};

const getNewsMediaPulseTracker = () => {
  return getPulseData();
};

export {
  getNewsMediaPulseTracker,
  getPulseTracker,
  updateObjectPage,
  updateOrigin,
  updateConsent,
  updatePulseUser,
};
export type { PTracker };

const getActorPromise = async (userId?: string, isPremium?: boolean) => {
  if (!userId) {
    return {
      id: undefined,
    };
  }

  return {
    id: parseInt(userId),
    subscriptionName: isPremium ? "Premium" : "No SubscribedTopic",
    realm: "schibsted.com",
  };
};

export { getActorPromise };

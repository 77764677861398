import type { Author } from "../../types/content";

const getAuthorsEmails = (authors: Author[]) =>
  authors.reduce((emails: string[], author) => {
    const emailContact = author.contacts?.find((contact) => {
      return contact.type === "email";
    });

    if (emailContact?.value) {
      emails.push(emailContact.value);
    }

    return emails;
  }, []);

export { getAuthorsEmails };

import { getNewsMediaPulseTracker } from "..";

const trackViewNewsMixPage = (id: string, name: string) => {
  const newsMediaPulseTracker = getNewsMediaPulseTracker();

  newsMediaPulseTracker?.executeNewsMediaPulseCommand("trackViewPage", {
    object: {
      pageId: id,
      name: name,
    },
  });
};
export { trackViewNewsMixPage };

import type { ReadonlyURLSearchParams } from "next/navigation";
import type { Article } from "../../types/content";

import { getPulseTracker, updateOrigin } from "..";
import { getNavigationType } from "../helpers/getNavigationType";

const newsroom: string =
  process.env.NEXT_PUBLIC_UI_THEME === "ekonomi" ? "omninext" : "omni";

const trackSalesPosterImpression = (
  url: string,
  article: Article,
  params: ReadonlyURLSearchParams,
) => {
  const pulseTracker = getPulseTracker();
  const navigationType = getNavigationType();

  pulseTracker?.executePulseCommand("track", "trackerEvent", {
    object: {
      id: article.article_id,
      type: "SalesPoster",
      salesPosterType: "Paid",
      elementType: "Subscription Promotion Area",
      name: "Article Sales Poster",
      tags: article.tags.filter((tag) => tag.title),
      category: article.category.title,
      navigationType,
      target: {
        id: article.article_id,
        type: "Article",
        name: article.title.value,
        url,
        accessLevel: article.is_premium ? "Paid" : "Free",
        accessLevelName: article.is_premium ? "premium" : "free",
        newsroom,
      },
    },
  });

  updateOrigin({
    id: article.article_id,
    type: "Article",
    url: window.location.href,
    name: article.title.value,
    source: params.get("utm_source") || undefined,
    channel: params.get("utm_medium") || params.get("utm_channel") || undefined,
    content: params.get("utm_content") || undefined,
    campaign: params.get("utm_campaign") || undefined,
    terms: params.get("utm_terms") || undefined,
  });
};

export { trackSalesPosterImpression };

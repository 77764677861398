import type { Article } from "../../types/content";

import { getPulseTracker } from "..";

const trackSubscriptionClick = (url: string, article?: Article) => {
  if (!article) {
    return null;
  }

  const pulseTracker = getPulseTracker();

  pulseTracker?.executePulseCommand("track", "engagementEvent", {
    action: "Click",
    object: {
      id: "button-subscribe",
      type: "UIElement",
      elementType: "Button",
      name: "Article Salesposter Subscribe Button",
      page: {
        id: article.article_id,
        type: "SalesPoster",
        salesPosterType: "Paid",
      },
    },
    target: {
      id: "checkout",
      type: "ExternalContent",
      name: "Web Salesposter",
      url,
    },
  });
};

export { trackSubscriptionClick };

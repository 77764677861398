import type { ArticleTeaserTargetSubset } from "../../types/pulse";

const getTeaserTarget = (
  article: ArticleTeaserTargetSubset,
  baseUrl: string,
) => {
  const trailingSlash = baseUrl.slice(-1) === "/";

  if (trailingSlash) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return {
    id: article.article_id,
    type: "Article",
    name: article.title.value,
    url: article.teaser?.link
      ? article.teaser?.link
      : `${baseUrl}/a/${article.article_id}`,
    accessLevel: article.is_premium ? "Paid" : "Free",
    accessLevelName: article.is_premium ? "premium" : "free",
    newsroom: process.env.NEXT_PUBLIC_UI_THEME === "core" ? "omni" : "omninext",
  };
};

export { getTeaserTarget };
